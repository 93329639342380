exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aitinstruments-js": () => import("./../../../src/pages/aitinstruments.js" /* webpackChunkName: "component---src-pages-aitinstruments-js" */),
  "component---src-pages-amcrys-js": () => import("./../../../src/pages/amcrys.js" /* webpackChunkName: "component---src-pages-amcrys-js" */),
  "component---src-pages-artray-js": () => import("./../../../src/pages/artray.js" /* webpackChunkName: "component---src-pages-artray-js" */),
  "component---src-pages-axetris-js": () => import("./../../../src/pages/axetris.js" /* webpackChunkName: "component---src-pages-axetris-js" */),
  "component---src-pages-be-lead-sensor-js": () => import("./../../../src/pages/beLeadSensor.js" /* webpackChunkName: "component---src-pages-be-lead-sensor-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-delivery-js": () => import("./../../../src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-direct-conversion-js": () => import("./../../../src/pages/directConversion.js" /* webpackChunkName: "component---src-pages-direct-conversion-js" */),
  "component---src-pages-exportcontrol-js": () => import("./../../../src/pages/exportcontrol.js" /* webpackChunkName: "component---src-pages-exportcontrol-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-hamamatsu-js": () => import("./../../../src/pages/hamamatsu.js" /* webpackChunkName: "component---src-pages-hamamatsu-js" */),
  "component---src-pages-ideas-js": () => import("./../../../src/pages/ideas.js" /* webpackChunkName: "component---src-pages-ideas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kuraray-js": () => import("./../../../src/pages/kuraray.js" /* webpackChunkName: "component---src-pages-kuraray-js" */),
  "component---src-pages-manufacturers-js": () => import("./../../../src/pages/manufacturers.js" /* webpackChunkName: "component---src-pages-manufacturers-js" */),
  "component---src-pages-ndl-js": () => import("./../../../src/pages/ndl.js" /* webpackChunkName: "component---src-pages-ndl-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pyreos-js": () => import("./../../../src/pages/pyreos.js" /* webpackChunkName: "component---src-pages-pyreos-js" */),
  "component---src-pages-scionix-js": () => import("./../../../src/pages/scionix.js" /* webpackChunkName: "component---src-pages-scionix-js" */),
  "component---src-pages-sout-js": () => import("./../../../src/pages/sout.js" /* webpackChunkName: "component---src-pages-sout-js" */),
  "component---src-templates-infoposts-js-content-file-path-content-info-privacy-mdx": () => import("./../../../src/templates/infoposts.js?__contentFilePath=/builds/development/ye-photonics/content/info/privacy.mdx" /* webpackChunkName: "component---src-templates-infoposts-js-content-file-path-content-info-privacy-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-01-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news01.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-01-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-02-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news02.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-02-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-03-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news03.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-03-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-04-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news04.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-04-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-05-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news05.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-05-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-06-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news06.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-06-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-07-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news07.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-07-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-08-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news08.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-08-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-09-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news09.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-09-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-10-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news10.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-10-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-11-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news11.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-11-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-12-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news12.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-12-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-14-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news14.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-14-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-15-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news15.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-15-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-16-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news16.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-16-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-17-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news17.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-17-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-18-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news18.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-18-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-19-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news19.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-19-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-20-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news20.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-20-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-21-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news21.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-21-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-22-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news22.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-22-mdx" */),
  "component---src-templates-news-js-content-file-path-content-news-23-mdx": () => import("./../../../src/templates/news.js?__contentFilePath=/builds/development/ye-photonics/content/news23.mdx" /* webpackChunkName: "component---src-templates-news-js-content-file-path-content-news-23-mdx" */)
}

